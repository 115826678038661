<template>
<div v-if="user">

  <div class="vx-row">

    <div class="vx-col md:w-1/2 w-full mb-6">
      <vx-card title="Employee">
        <div class="vx-row">

          <div class="vx-col md:w-1/1 w-full mb-6">
            <vs-input :label="$i18n.t('Name')" v-model="form.employee.name" class="w-full" />
          </div>

          <div class="vx-col md:w-1/1 w-full mb-6">
            <vs-input :label="$i18n.t('KTPNumber')" v-model="form.employee.id_card_number" class="w-full" />
          </div>

          <div class="vx-col md:w-1/1 w-full mb-6">
            <vs-input :label="$i18n.t('PlaceOfBirth')" v-model="form.employee.place_birth" class="w-full" />
          </div>

          <div class="vx-col md:w-1/1 w-full mb-6">
            <vs-input :label="$i18n.t('DateOfBirth')" v-model="form.employee.date_birth" class="w-full" />
          </div>

          <div class="vx-col md:w-1/1 w-full mb-6">
            <label for="" class="vs-input--label">{{$i18n.t('Address')}}</label>
            <vs-textarea rows="8" v-model="form.employee.address" class="w-full" />
          </div>

        </div>
      </vx-card>
    </div>

    <div class="vx-col md:w-1/1 w-full mb-6">
      <vx-card title="Family" v-if="employee.families">
        <vs-table :data="employee.families">
          <template slot="thead">
            <vs-th>Nama</vs-th>
            <vs-th>Hubungan</vs-th>
            <vs-th>Tanggal Lahir</vs-th>
            <vs-th>Pekerjaan</vs-th>
            <vs-th>Alamat</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">

              <vs-td :data="data[indextr].name">
                <vs-input size="small" v-model="data[indextr].name" class="w-full" />
              </vs-td>

              <vs-td :data="data[indextr].relation">
                <vs-input size="small" v-model="data[indextr].relation" class="w-full" />
              </vs-td>

              <vs-td :data="data[indextr].birth">
                <vs-input size="small" v-model="data[indextr].birth" class="w-full" />
              </vs-td>

              <vs-td :data="data[indextr].work">
                <vs-input size="small" v-model="data[indextr].work" class="w-full" />
              </vs-td>

              <vs-td :data="data[indextr].address">
                <vs-textarea size="small" v-model="data[indextr].address" class="w-full" />
              </vs-td>

            </vs-tr>
          </template>

        </vs-table>
      </vx-card>
    </div>

  </div>


</div>
</template>

<script>
export default {
  name: "Profile",
  data: () => ({
    user: false,
    form: {
      user: {}
    },
    employee: {}
  }),
  mounted() {
    if (!this.$route.params.id) {
      this.user = this.$store.state.AppActiveUser;
      this.form = {
        employee: this.user.employee,
      }

      this.$store.dispatch('employee/detailItem', {id: this.user.nik}).then(resp => {
        this.employee = resp.data.values;
        console.log(this.employee);
      });
    }
  }

}
</script>

<style scoped>

</style>
